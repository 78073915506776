import * as React from "react"
import { Link } from "gatsby"
import {
  withPrismicUnpublishedPreview,
} from 'gatsby-plugin-prismic-previews'

import LayoutUpdate from "../components/layout/layout-update"
import Seonew from "../components/seo-new"

const NotFoundPage = () => ( 
  <LayoutUpdate>
    <Seonew title="404: Page Not found" />
    
    <div className="w-full h-screen bg-site-gray flex flex-col items-center justify-center px-6">
      <div>
        <h1 className="text-6xl font-extrabold text-site-yellow text-center mb-6">404: Page Not Found</h1>
        <p className="text-lg text-white font-semibold text-center">You just hit a route that does not exist.</p>
        <Link to="/">
          <p className="text-center text-site-yellow mt-4 uppercase">Go to Home Page</p>
        </Link>
      </div>
    </div>
  </LayoutUpdate>
)

export default withPrismicUnpublishedPreview(NotFoundPage)
